import { AuthenticationApi } from "@eduthings/app-scripts/generated/apis/AuthenticationApi";
import { DistrictApi } from "@eduthings/app-scripts/generated/apis/DistrictApi";
import { action, observable } from "mobx";

export class DistrictSwitcherViewModel {
    constructor() {
        const debounce = (fn: () => void) => {
            let timed_fn: ReturnType<typeof setTimeout> | undefined;
            return () => {
                if (!timed_fn) {
                    fn.apply(this);
                }
                clearTimeout(timed_fn);
                timed_fn = setTimeout(() => {
                    timed_fn = undefined;
                }, 300);
            };
        };

        const validateDistrictIsSameIfVisible = () => {
            if (!document.hidden) {
                debounce(this.validateDistrictIsSame)();
            }
        };

        document.addEventListener("visibilitychange", (evt) => {
            validateDistrictIsSameIfVisible();
        });
        document.addEventListener("focus", () => {
            validateDistrictIsSameIfVisible();
        });

        window.addEventListener("focus", (evt) => {
            validateDistrictIsSameIfVisible();
        });
    }

    @observable
    accessor districtId = 0;

    @observable
    accessor districtName: string | undefined;

    @observable
    accessor newDistrictName: string | undefined;

    @observable
    accessor newDistrictId = 0;

    @observable
    accessor isVisible = false;

    @action
    async validateDistrictIsSame() {
        const client = new AuthenticationApi();
        const currentState = await client.get();

        const breadcrumbDistrictName = document.getElementById("district_name_breadcrumb")?.innerHTML.trim() ?? "";
        const switcherDistrictName = document.getElementById("switch-district")?.firstElementChild?.innerHTML.trim() ?? "";

        // If authContext refreshed, the districtId will be set to the new districtId in DistrictSwitcher even if parts of the app continue to contain references to the old district...
        if ((currentState.districtId != this.districtId && currentState.districtId) || (breadcrumbDistrictName != switcherDistrictName && breadcrumbDistrictName)) {
            const districtClient = new DistrictApi();

            const currentDistrict = await districtClient.get(this.districtId);
            if (currentState.districtId == null) {
                return;
            }
            const newDistrict = await districtClient.get(currentState.districtId);

            this.districtName = currentDistrict?.name ?? "";
            this.newDistrictName = newDistrict?.name ?? "";
            this.newDistrictId = newDistrict.districtId;

            this.isVisible = true;
        } else {
            this.isVisible = false;
        }
    }
}
