import { AuthContext } from "@eduthings/app-scripts/models/security/AuthContext";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import AccountSelector from "./components/AccountSelector";
import DistrictSwitcher from "./components/DistrictSwitcher";
import DistrictSwitcherUI from "./components/DistrictSwitcherUI";

const authContext = new AuthContext();

const switchDistrictElement = document.getElementById("switch-district");
if (switchDistrictElement) {
    authContext.loadSate().then((state) => {
        const root = ReactDOM.createRoot(switchDistrictElement);
        root.render(
            <StrictMode>
                <DistrictSwitcher authContext={authContext} />
                <DistrictSwitcherUI authContext={authContext} />
            </StrictMode>
        );
    });
}

const accountSelectorElement = document.getElementById("account-selector");
if (accountSelectorElement) {
    authContext.loadSate().then((state) => {
        if (authContext.isInRole("100") || authContext.isInRole("75") || authContext.isInRole("60")) {
            const root = ReactDOM.createRoot(accountSelectorElement);
            root.render(
                <StrictMode>
                    <AccountSelector currentAccountId={accountSelectorElement.dataset.accountId || "0"} currentDistrictId={accountSelectorElement.dataset.accountDistrict || "0"} isReact={false} />
                </StrictMode>
            );
        }
    });
}
